<template>
  <b-overlay
    :show="submitting"
    spinner-variant="primary"
    spinner-type="grow"
    :variant="darkMode?'dark':'white'"
    spinner-small
    rounded="md"
  >
    <div class="auth-wrapper auth-v2">
      <b-row class="auth-inner m-0 justify-content-center">
        <div
          class="col-12 col-lg-6 col-xl-6 d-flex align-items-center auth-bg px-2 p-lg-5"
        >
          <div
            class="col-12 col-sm-8 col-md-6 col-lg-12 px-xl-2 mx-auto"
          >
            <p class="text-center">
              <auth-logo />
            </p>
            <b-card-title
              title-tag="h2"
              class="font-weight-bold mb-1 text-center"
            >
              {{ $t('welcome_to') }} Lenon Affiliate
            </b-card-title>
            <b-card-text class="mb-2 text-center">
              Please create a new account
            </b-card-text>

            <error-display :error="error" />
            <!-- form -->
            <validation-observer
              ref="registerForm"
              v-slot="{invalid}"
            >
              <b-form
                class="auth-login-form mt-2"
                @submit.prevent="register()"
              >
                <div class="row">
                  <div class="col-lg-6">
                    <lenon-input
                      v-model="affiliate.first_name"
                      name="first_name"
                      rules="required|max_length:50"
                      label="First Name"
                      placeholder="First Name"
                    />
                  </div>
                  <div class="col-lg-6">
                    <lenon-input
                      v-model="affiliate.last_name"
                      name="last_name"
                      rules="required|max_length:50"
                      label="Last Name"
                      placeholder="Last Name"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6">
                    <lenon-input
                      v-model="affiliate.phone"
                      name="phone"
                      rules="required|max_length:20"
                      label="Phone"
                    />
                  </div>
                  <div class="col-lg-6">

                    <lenon-input
                      v-model="affiliate.email"
                      name="email"
                      rules="required|email"
                      label="Email"
                      placeholder="Email"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-12">
                    <lenon-input
                      v-model="affiliate.address"
                      name="address"
                      rules="required"
                      label="Address"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6">
                    <lenon-input
                      v-model="affiliate.password"
                      name="password"
                      rules="required"
                      type="password"
                      label="Password"
                      suffix-icon="LockIcon"
                    />
                  </div>
                  <div class="col-lg-6">

                    <lenon-input
                      v-model="affiliate.password_confirmation"
                      name="confirm_password"
                      rules="required|confirmed:password"
                      type="password"
                      label="Confirm"
                      placeholder="Confirm Password"
                      suffix-icon="LockIcon"
                    />
                  </div>
                </div>
                <div class="mt-1 d-flex justify-content-between align-items-start">
                  <lenon-button
                    variant="outline-warning"
                    label="Login"
                    icon="ArrowLeftCircleIcon"
                    @onClick="()=>{$router.push({name: 'affiliate-login'})}"
                  />
                  <lenon-button
                    name="submit-button"
                    class="float-right"
                    label="register"
                    :disabled="invalid"
                    type="submit"
                  />
                </div>
              </b-form>
            </validation-observer>
          </div>
        </div>
        <!-- /Login-->
      </b-row>
    </div>
  </b-overlay>
</template>

<script>
import { ValidationObserver } from 'vee-validate'
import {
  BRow,
  BCardText,
  BCardTitle,
  BForm,
  BOverlay,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import useAppConfig from '@core/app-config/useAppConfig'
import LenonInput from '@/lenon/components/LenonInput.vue'
import LenonButton from '@/lenon/components/LenonButton.vue'
import showToast from '@/lenon/mixins/showToast'
import ErrorDisplay from '@/lenon/components/ErrorDisplay.vue'
import AuthLogo from '@/lenon/components/AuthLogo.vue'

const { skin } = useAppConfig()
export default {
  components: {
    AuthLogo,
    ErrorDisplay,
    LenonButton,
    LenonInput,
    BRow,
    BCardText,
    BCardTitle,
    BForm,
    ValidationObserver,
    BOverlay,
  },
  directives: {
    Ripple,
  },
  mixins: [showToast],
  data() {
    return {
      error: {},
      submitting: false,
      isPasswordVisible: false,
      isConfirmPasswordVisible: false,
      affiliate: {
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        address: '',
        password: '',
        password_confirmation: '',
      },
    }
  },
  computed: {
    darkMode() {
      return skin.value === 'dark'
    },
  },
  methods: {
    register() {
      this.error = {}
      this.submitting = true
      this.$http.post('affiliates/register', this.affiliate)
        .then(() => {
          this.submitting = false
          this.showSuccess('Account created successfully, please login.')
          this.$router.push({ name: 'affiliate-login' })
        })
        .catch(error => {
          this.showError('Something went wrong')
          this.submitting = false
          this.error = error
        })
    },
  },
  // },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
